// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Beneficiary } from '@beneficiary/types';
import type { Participant } from '@participant/types';
import type { GroupClassRequest } from '@participant/types';
import type { UserRegistered } from '@groupClass/types';

import DescriptionContainer from '_common/components/description-container/DescriptionContainer';
import LabelInformation from '_common/components/label-information/LabelInformation';
import Checkbox from '@shared/Checkbox/component/Checkbox';

import DateService from '_common/services/DateService';
import FormatHelpers from '@helpers/FormatHelpers';

import { OPTIONS_DATE_FORMAT } from '@app/constants/constants';

type Props = {
  data: UserRegistered | GroupClassRequest | null,
  user: Beneficiary | Participant | null,
};

const PersonalInformation = (props: Props): React$Node => {
  const { user, data } = props;
  const { t, i18n: { language } } = useTranslation();

  const {
    email = '',
    mobilePhone = '',
    landlinePhone = '',
    address = {},
    sexe= '',
  } = user || {};

  const {
    city = '',
    postalCode = '',
    streetName = '',
    complementNumber = '',
  } = address;

  const mobilePhoneFormatted = useMemo((): string => (
    mobilePhone
      ? FormatHelpers.phoneFormatter(mobilePhone)
      : ''
  ), [mobilePhone]);

  const landlinePhoneFormatted = useMemo((): string => (
    landlinePhone
      ? FormatHelpers.phoneFormatter(landlinePhone)
      : ''
  ), [landlinePhone]);

  const country: string = useMemo((): string => (
    user?.address?.country ? user.address.country.toLowerCase() : ''
  ), [user]);

  const birthDate: string = useMemo((): string => (
    user?.birthDate ? new Date(user.birthDate).toLocaleDateString(language, OPTIONS_DATE_FORMAT) : ''
  ), [user]);

  return (
    <>
      <div className="column is-12 p-0">
        <div className="block">
          <div className="columns is-flex-wrap-wrap">
            <div className="column is-12">
              <h2 className="label-title">{ t('groupClasses.groupClass.peoples.form.peopleContact') }</h2>
            </div>
            <div className="column is-6-desktop p-0">
              <div className="columns">
                <LabelInformation
                  title={ t('user.gender') }
                  value={ sexe ? t(`gender.${ sexe }`) : '' }
                  required
                />
              </div>
              <div className="columns">
                <LabelInformation
                  title={ t('user.birthDate') }
                  value={ birthDate }
                  className="first-element"
                  required
                />
                <LabelInformation title={ t('user.mobilePhone') } value={ mobilePhoneFormatted } />
              </div>
              <div className="columns">
                <LabelInformation title={ t('login.email') } value={ email } />
                <LabelInformation title={ t('user.landlinePhone') } value={ landlinePhoneFormatted } />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="column is-12 p-0">
        <div className="address">
          <div className="block">
            <div className="columns is-flex-wrap-wrap">
              <div className="column is-12">
                <h2 className="label-title">{ t('groupClasses.groupClass.detailed.address.address') }</h2>
              </div>
              <div className="column is-12 p-0">
                <div className="columns is-flex-wrap-wrap">
                  <div className="column is-6-desktop p-0">
                    <div className="columns is-flex-wrap-wrap">
                      <LabelInformation title={ t('groupClasses.groupClass.detailed.address.address') }
                        value={ streetName } />
                    </div>
                  </div>
                  <div className="column is-6-desktop p-0">
                    <div className="columns is-flex-wrap-wrap">
                      <LabelInformation title={ t('groupClasses.groupClass.detailed.address.addressComplement') }
                        value={ complementNumber } />
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-12 p-0">
                <div className="columns is-flex-wrap-wrap">
                  <div className="column is-6-desktop p-0">
                    <div className="columns is-flex-wrap-wrap">
                      <LabelInformation title={ t('groupClasses.groupClass.detailed.address.postalCode') }
                        value={ postalCode } required />
                    </div>
                  </div>
                  <div className="column is-6-desktop p-0">
                    <div className="columns is-flex-wrap-wrap">
                      <LabelInformation title={ t('groupClasses.groupClass.detailed.address.city') } value={ city }
                        required />
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-6-desktop p-0">
                <div className="columns is-flex-wrap-wrap">
                  <LabelInformation
                    title={ t('user.address.country') }
                    value={ t(`countries.${ country }`) }
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column is-12">
        <div className="block block-group-classes-subscribed">
          <DescriptionContainer trad={ 'user.registeredAt' }>
            <ul className="registered-to">
              { data?.groupClassesRegisteredTo?.map((groupClass) => {
                const { day, startTime, endTime } = groupClass;
                const groupClassCity = groupClass.address.city;
                const { name } = groupClass.activity;
                const groupClassDayTime = DateService.localDateAndTimeDisplay(
                  day,
                  startTime,
                  endTime,
                  language,
                );
                return (
                  <li key={ groupClass.id }>
                    { name && <span>{ name } | </span> }
                    <span className="uppercase-first inline-block">{ groupClassCity }</span>
                    <span>{ ` - ${ groupClassDayTime }` }</span>
                  </li>
                );
              }) }
            </ul>
          </DescriptionContainer>
        </div>
      </div>
      <div className="column is-12">
        <Checkbox checked isDisabled required className="checkbox-label">
          { t('groupClasses.groupClass.peoples.form.authorizeData') }
        </Checkbox>
      </div>
    </>
  );
};

export default PersonalInformation;
